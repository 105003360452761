import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"

import PikselFs from "../../../components/fullscreen/PikselFs"
import Seo from "../../../components/Seo"
import OluPikselGaleri from "../../../contents/Gallery/OluPikselGaleri"
import SvgLeftIcon from "../../../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../../../contents/SVG/SvgPixelTest"
import EndIndicator from "../components/EndIndicator"
import StartIndicator from "../components/StartIndicator"
const DeadPixelTest = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        lang="pt"
        keywords="teste de pixels morto, teste de pixel preso, reparação de pixels preso, reparação de pixels morta, teste de pixels preso, o que é pixel morto, o que é pixelado, o que é pixelado preso, o que é pixel preso, pixel morto Diferença de pixel, teste de pixels, teste de pixel, pixel morto, teste de pixel, pixel preso, reparação de pixel online"
        title="Teste de pixel morto e preso"
        description="Teste pixels mortos e pixels presos em seus telefones Android, Telefones, Tablet, PC, Televisão Inteligente, Monitor e tela de computador no site gratuito sem qualquer instalação."
      />
      <h1 className="text-center md:text-left">
        Pixel morto ou teste de pixel preso
      </h1>
      <p className="lead">
        Você pode detectar pixels mortos ou pixels presos na tela, se houver,
        clicando no botão <code>Test Dead - Stuck Pixels</code> abaixo, sem
        qualquer configuração.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Antes de iniciar o teste de pixelado morto, você deve considerar:
        </p>
        <p className="">
          Antes de fazer o teste de pixel morto, verifique se sua tela está
          limpa e Seu ambiente não é muito brilhante.Enquanto a poeira na sua
          tela pode enganar você, o brilho excessivo pode fazer com que você
          perca pixels mortos.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Test Dead - Stuck Pixels"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2>Como posso testar pixels mortos ou pixels presos?</h2>
      <p>
        Depois de clicar no botão <code>Test Dead - Stuck Pixels</code> acima, o
        site irá para a tela cheia.As informações estão disponíveis na tela que
        se abre.
      </p>
      <p>
        Para detectar pixels mortos ou pixels presos, você precisa verificar as
        cores vermelhas, verdes, azuis, preto e brancas que ocupam a tela cheia
        uma por uma.
      </p>

      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        Para alternar entre as cores em tela inteira, você pode clicar em
        qualquer lugar da tela com o botão esquerdo do mouse ou pressionar as
        teclas <SvgLeftIcon className="inline text-purple-700 fill-current" /> e{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" />{" "}
        (esquerda e direita) do teclado.
      </p>
      <blockquote>
        Antes de iniciar o pixel morto - teste de pixel preso por favor, se
        preocupe com aviso acima da caixa de informações azul no topo.
      </blockquote>
      <p>
        Se você detectar um ponto ou pontos com uma cor diferente do que a cor
        de fundo cobrindo sua tela, isso significa que você tem um pixel morto
        ou um pixel preso na tela.
      </p>

      <blockquote>
        Você pode pressionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para sair do teste.
      </blockquote>
      <p>
        Você pode ver imagens de amostra de pixels mortos ou pixels presos
        clicando no botão <code>Dead - Stuck Pixel Amosple</code> abaixo.
      </p>
      <OluPikselGaleri text="Dead - Stuck Pixel Amosple" />
      <h2>O que é pixel morto?</h2>
      <p>
        Pixel morto é um pixel que não funciona em sua tela por não ter
        energia.Por esta razão, os pixels mortos geralmente são negros.
      </p>
      <p>
        Embora os pixels mortos sejam principalmente negros, eles podem ser
        brancos em alguns monitores.
      </p>
      <p>
        Para obter mais informações sobre pixel morto e reparo de pixel morto,
        você pode ir para a página relevante clicando no botão de{" "}
        <code className="capitalize">reparo de pixel morto</code> abaixo.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/dead-pixel-repair"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">reparo de pixel morto</span>
        </Link>
      </div>

      <h2>O que está preso pixel?</h2>
      <p className="lead">
        Ao contrário dos pixels mortos, os pixels presos ainda recebem energia,
        por este motivo, a reparação de pixels presas é muito mais fácil.
      </p>
      <p>
        Pixels presos são pixels que permanecem principalmente em cores básicas,
        como vermelho, verde, azul por razões como ficar da mesma cor por um
        longo tempo, e refletem a mesma cor continuamente.
      </p>
      <p>
        Para obter mais informações sobre pixels presos e reparar pixels presos,
        você pode ir para a página correspondente clicando no botão de{" "}
        <code className="capitalize">reparo de pixel preso</code> abaixo.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correcao-de-pixel-travado"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">reparo de pixel preso</span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicator />}
        endIndicator={
          <EndIndicator
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default DeadPixelTest
