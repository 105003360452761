import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicator = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="Parabéns">
          🎉
        </span>
        Você completou com sucesso o teste de pixel morto
        <span role="img" aria-label="Parabéns">
          🎉
        </span>
      </p>
      <p className="lead">Espero que não haja pixels mortos na sua tela.</p>
      <p className="lead">
        Você pode pressionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para sair do teste.
      </p>
      <div>
        <RedSolidButton
          text="Saída"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Retestar" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicator)
