import React, { memo } from "react"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
const StartIndicator = () => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        Você pode iniciar o teste de pixel morto clicando em qualquer lugar na
        tela ou pressionando a tecla{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" />{" "}
        (direita) do teclado.
      </p>
      <p className="lead">
        Você pode pressionar{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        para sair do teste.
      </p>
    </div>
  )
}
export default memo(StartIndicator)
